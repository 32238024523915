import { AxiosError, AxiosResponse } from 'axios';
import { IAgencySignup } from '../types/agency-signup-type';
import { ICustomerLead } from '../types/customer-lead-type';
import { IEsito, IMicroEsito } from '../types/esiti-type';
import { Pagination } from '../types/pagination-type';
import { IProgressType } from '../types/progress-type';
import { ITicket } from '../types/ticket-type';
import { Network } from '../utils/Network';

interface IGetAgencyRequest {
	page: number;
	value?: string | null;
	size?: number;
}

interface IAgencyService {
	getSignedDocument(leadUuid: string): Promise<AxiosResponse<string, AxiosError>>;
	sendSignDocument(leadUuid: string): Promise<void>;
	getContracts(leadUuid: string): Promise<AxiosResponse<string[], AxiosError>>;
	createTicket(ticket: ITicket): Promise<AxiosResponse<ITicket, AxiosError>>;
	updateTicketByUuid(ticket: ITicket): Promise<AxiosResponse<ITicket, AxiosError>>;
	getTicketsByAgencyUuid(
		page: number,
		size: number,
	): Promise<AxiosResponse<Pagination<ITicket[], 'data'>, AxiosError>>;
	getFilesUploaded(): Promise<AxiosResponse<any[], AxiosError>>;
	updateVisibilitLeadByFile(fileId: string, hide: boolean): Promise<void>;
	getLeadsFilter(
		page: number,
		size: number,
		channel: string,
		microesito: string,
	): Promise<AxiosResponse<Pagination<ICustomerLead[], 'data'>, AxiosError>>;
	getChannels(): Promise<AxiosResponse<string[], AxiosError>>;
	createChannel(canale: string): Promise<AxiosResponse<void, AxiosError>>;
	startPayment(amount: string, type: string): Promise<AxiosResponse<string, AxiosError>>;
	deleteDocument(id: string, leadUuid: string, praticaId: string): Promise<void>;
	getAllMicroEsiti(): Promise<AxiosResponse<IMicroEsito[], AxiosError>>;
	deleteMicroEsito(id: number): Promise<AxiosResponse<void, AxiosError>>;
	createMicroEsito(body: IMicroEsito): Promise<AxiosResponse<void, AxiosError>>;
	getPaginatedProgress(arg0: {
		page: number;
		value: string;
		size: number;
	}): Promise<AxiosResponse<Pagination<IProgressType[], 'data'>, AxiosError>>;
	updateEsitoLead(
		leadId: string,
		body: IMicroEsito,
		note: string,
	): Promise<AxiosResponse<ICustomerLead, AxiosError>>;
	changePasswordFirstLogin(
		uuid: string,
		password: string,
		confirmPassword: string,
		isAdmin: boolean,
	): Promise<void>;
	requestNewLeads(
		uuid: string,
		requestLead: number,
		distributionDaily: number,
		valueSms: number,
	): Promise<void>;
	getAgencyInformation: (id: string) => Promise<AxiosResponse<IAgencySignup, AxiosError>>;
	getRevisionsAgencies(
		id: number,
	): Promise<AxiosResponse<Pagination<IAgencySignup[], 'data'>, AxiosError>>;
	getOutcome(): Promise<AxiosResponse<IEsito[], AxiosError>>;
	assignAgency(leadId: string, agencyId: string): Promise<AxiosResponse<void, AxiosError>>;
	getPaginatedAgencies: (
		props: IGetAgencyRequest,
	) => Promise<AxiosResponse<Pagination<IAgencySignup[], 'data'>, AxiosError>>;
	createAgency: (body: IAgencySignup) => Promise<AxiosResponse<void, AxiosError>>;
	getLeadsAgency: (
		props: IGetAgencyRequest,
	) => Promise<AxiosResponse<Pagination<ICustomerLead[], 'data'>, AxiosError>>;
	uploadDocument: (
		file: File,
		leadUuid: string,
		praticaId: string,
	) => Promise<AxiosResponse<void, AxiosError>>;
	downloadDocument: (
		filename: string,
		leadUuid: string,
		praticaId: string,
	) => Promise<AxiosResponse<Blob, AxiosError>>;
	getAllDocuments(
		leadUuid: string,
		praticaId: string,
	): Promise<AxiosResponse<string[], AxiosError>>;
}

const AgencyService: IAgencyService = {
	getPaginatedAgencies: ({ page, value, size }) =>
		Network.get(`/v1/api/agency`, {
			params: {
				page,
				size,
				value,
			},
		}),
	createAgency: (body) => {
		return Network.post(`/public/authentication/signup`, {
			username: body.username,
			agencyProps: {
				nome: body.name,
			},
			password: body.password,
			roles: ['AGENCY'],
			email: body.email,
		});
	},
	assignAgency: (leadId, agencyId) => {
		return Network.get(`/v1/api/leads/${leadId}/assign`, { params: { agencyId } });
	},
	getLeadsAgency: ({ page, size, value }) =>
		Network.get(`/v1/api/agency/leads`, {
			params: {
				page,
				size,
				value,
			},
		}),
	getOutcome: () => Network.get(`/v1/api/esiti`),
	getRevisionsAgencies: (id) =>
		Network.get(`/v1/api/agencies/${id}/history`, { params: { page: 0, size: 100 } }),
	getAgencyInformation: (id) => Network.get(`/v1/api/agency/${id}`),
	requestNewLeads: (uuid, credit, distributionDaily, valueSms) =>
		Network.post(`/v1/api/agency/${uuid}/request/lead`, {
			credit,
			distributionDaily,
			creditSms: valueSms,
		}),
	changePasswordFirstLogin: (uuid, password, confirmPassword, isAdmin) =>
		Network.put(`/v1/api/agency/${uuid}/changePassword`, {
			password,
			confirmPassword,
			isAdmin: isAdmin,
		}),
	updateEsitoLead: (leadId, body, note) => Network.put(`/v1/api/esiti/${leadId}/update`, body),
	getPaginatedProgress: ({ page, value, size }) =>
		Network.get(`/v1/api/leads/remaining`, {
			params: {
				page,
				size,
				value,
			},
		}),
	createMicroEsito: (body) => Network.post(`/v1/api/micro-esito`, body),
	deleteMicroEsito: (id) => Network.delete(`/v1/api/micro-esito/${id}`),
	getAllMicroEsiti: () => Network.get(`/v1/api/micro-esito`),
	uploadDocument: (file, leadUuid, praticaId) => {
		const formData = new FormData();
		formData.append('file', file);
		return Network.post(`/v1/api/agency/document`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			params: { leadUuid, praticaId },
		});
	},
	downloadDocument: (filename, leadUuid, praticaId) =>
		Network.get(`/v1/api/agency/document/download`, {
			responseType: 'blob',
			params: { filename, leadUuid, praticaId },
		}),
	getAllDocuments: (leadUuid, praticaId) =>
		Network.get(`/v1/api/agency/documents`, { params: { leadUuid, praticaId } }),
	deleteDocument: (id, leadUuid, praticaId) =>
		Network.delete(`/v1/api/agency/document/${id}`, { params: { leadUuid, praticaId } }),
	startPayment: (amount, type) => Network.post(`/v1/api/checkout?amount=${amount}&type=${type}`),
	createChannel: (canale) => Network.post(`/v1/api/agency/channel?canale=${canale}`),
	getChannels: () => Network.get(`/v1/api/agency/channel`),
	getLeadsFilter: (page, size, channel, microesito) =>
		Network.get(`/v1/api/agency/leads/filter`, {
			params: {
				page,
				size,
				channel,
				microesito,
			},
		}),
	getFilesUploaded: () => Network.get(`/v1/api/agency/files/uploaded`),
	updateVisibilitLeadByFile: (fileId, hide) =>
		Network.get(`/v1/api/agency/files/uploaded/${fileId}`, { params: { hide } }),
	getTicketsByAgencyUuid: (page, size) => Network.get(`/v1/api/ticket`, { params: { page, size } }),
	updateTicketByUuid: (ticket) => Network.put(`/v1/api/ticket`, ticket),
	createTicket: (ticket) => Network.post(`/v1/api/ticket`, ticket),
	getContracts: (leadUuid) => Network.get(`/v1/api/agency/contracts`, { params: { leadUuid } }),
	sendSignDocument: (leadUuid) => Network.get(`/v1/api/lead/${leadUuid}/signPdf`),
	getSignedDocument: (leadUuid) => Network.get(`/v1/api/lead/${leadUuid}/signed-pdf/retrieve`),
};

export default AgencyService;
