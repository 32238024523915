export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Crea anagrafica',
		path: 'lead/',
		icon: 'People',
	},
	anagrafica: {
		id: 'anagrafica',
		text: 'Cerca anagrafica',
		path: 'leads-table/',
		icon: 'PersonSearch',
	},
	// agency: {
	// 	id: 'agency',
	// 	text: 'Agenzie',
	// 	path: 'create-agency/',
	// 	icon: 'AddBusiness',
	// },
	operators: {
		id: 'operators',
		text: 'Operatori',
		path: 'operators',
		icon: 'SupportAgent',
	},
	groups: {
		id: 'groups',
		text: 'Gruppi',
		path: 'groups',
		icon: 'LocalOffer',
	},
	// report: {
	// 	id: 'reports',
	// 	text: 'Report operatori',
	// 	path: 'reports',
	// 	icon: 'Summarize',
	// },
	// template: {
	// 	id: 'template',
	// 	text: 'Crea template',
	// 	path: 'template/',
	// 	icon: 'IntegrationInstructions',
	// },
	// updateTemplate: {
	// 	id: 'updateTemplate',
	// 	text: 'Modifica template',
	// 	path: 'update-template/',
	// 	icon: 'Update',
	// },
};

export const agencyPagesMenu = {
	agency: {
		id: 'home',
		text: 'Homepage',
		path: 'home-agency',
	},
};
export const agencyPagesAppointment = {
	appointment: {
		id: 'appuntamenti',
		text: 'Appuntamenti',
		path: 'appointment-agency',
	},
};

export const messageUnreadPages = {
	message: {
		id: 'notification',
		text: 'Messaggi non letti',
		path: 'notification',
	},
};

export const requestLeadPage = {
	requestLead: {
		id: 'requestLead',
		text: 'Richiedi nuovi lead',
		path: 'request-lead',
	},
};

export const loginPagesMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
	},
};
export const homePage = {
	login: {
		id: 'homepage',
		text: 'Homepage',
		path: 'homepage',
	},
};

export const unloggedPages = {
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
		icon: 'ReportGmailerrorred',
	},
};
export const stripePages = {
	paymentSuccess: {
		id: 'Pagamento effettuato',
		text: 'Pagamento effettuato',
		path: 'payment-success',
	},
	paymentCharge: {
		id: 'Pagamento',
		text: 'Pagamento',
		path: 'payment',
	},
};
export const progressOperator = {
	progress: {
		id: 'report',
		text: 'Report',
		path: 'report',
		icon: 'ReportGmailerrorred',
	},
};
export const agencyTemplateSms = {
	templateSms: {
		id: 'templateSms',
		text: 'Template SMS',
		path: 'template-sms',
	},
};
export const progressAgency = {
	progress: {
		id: 'report',
		text: 'Report agenzia',
		path: 'report-agency',
		icon: 'ReportGmailerrorred',
	},
};
