export const LOCAL_STORAGE_USER_KEY = 'facit_authUsername';

export const LOCAL_STORAGE_TOKEN_KEY = '__token';

export const BASE_URL = 'https://gmw.megawattlucegas.it:8080/';
// export const BASE_URL = 'http://localhost:8080/';

export const DEFAULT_TIMEOUT_REQUESTS = 30000;

export const DEFAULT_ITEMS_FOR_PAGE = 10;

export const INITIAL_PAGE_NUMBER = 0;

export const PUBLIC = '__public';
