import { AxiosError, AxiosResponse } from 'axios';
import { IGroups } from '../types/groups-type';
import { Pagination } from '../types/pagination-type';
import { Network } from '../utils/Network';

interface IAdminService {
	exportReport(type: string): Promise<AxiosResponse<string, AxiosError>>;
	assignGroupToAgency(uuid: string, group: string): Promise<void>;
	deleteGroupById(id: number): Promise<void>;
	assignGroups(groups: string[], operatorId: number): Promise<void>;
	getPaginatedGroups(arg0: {
		page: number;
		value: string;
		size: number;
	}): Promise<AxiosResponse<Pagination<IGroups[], 'data'>, AxiosError>>;
	createGroups(nome: string): Promise<void>;
	setCredits: (
		uuid: string,
		credit: number,
		numFattura: string,
		creditSms: number,
	) => Promise<void>;
	setEnabled: (uuid: string, toggle: boolean) => Promise<void>;
}

const AdminService: IAdminService = {
	setCredits: async (uuid, credit, numFattura, creditSms) => {
		return Network.post(`/v1/api/agency/${uuid}/credits`, {
			credit,
			numFattura,
			creditSms,
		});
	},
	setEnabled: async (uuid, toggle) => {
		return Network.get(`/v1/api/agency/${uuid}/enabled	`, {
			params: {
				toggle,
			},
		});
	},
	createGroups: async (nome) => {
		return Network.post(`/v1/api/groups`, {
			nome,
		});
	},
	getPaginatedGroups: ({ page, value, size }) =>
		Network.get(`/v1/api/groups`, {
			params: {
				page,
				size,
				value,
			},
		}),
	assignGroups: (groups, operatorId) => {
		return Network.put(`/v1/api/groups/${operatorId}`, {
			groups,
		});
	},
	deleteGroupById: (id) => {
		return Network.delete(`/v1/api/groups/${id}`);
	},
	assignGroupToAgency: (uuid, group) => {
		return Network.put(`/v1/api/agency/${uuid}/group/${group}`);
	},
	exportReport: async (type) => {
		return Network.get(`/v1/api/export/report/${type}`, {
			headers: {
				responseType: 'blob',
			},
		});
	},
};

export default AdminService;
