import { create } from 'zustand';

import moment from 'moment';
import { DEFAULT_ITEMS_FOR_PAGE } from '../common/constants';
import showNotification from '../components/extras/showNotification';
import AdminService from '../services/AdminService';
import AgencyService from '../services/AgencyService';
import { IAgencySignup } from '../types/agency-signup-type';
import { ICustomerLead } from '../types/customer-lead-type';
import { IEsito, IMicroEsito } from '../types/esiti-type';
import { Page, Pagination } from '../types/pagination-type';
import { IProgressType } from '../types/progress-type';
import { ITicket } from '../types/ticket-type';

interface IGetAgencyRequest {
	page: number;
	value?: string | null;
	size?: number;
}

interface IAgencyState {
	revisionsAgency: Pagination<IAgencySignup[], 'data'> | undefined;
	paginatedAgencies: Pagination<IAgencySignup[], 'data'> | undefined;
	agencyInformation: IAgencySignup | undefined;
	loadingAgency: boolean;
	paginatedLeadAgency: Pagination<ICustomerLead[], 'data'> | undefined;
	outcomes: IEsito[] | [];
	paginatedProgress: Pagination<IProgressType[], 'data'> | undefined;
	microEsiti: IMicroEsito[] | [];
	documents: string[] | [];
	contracts: string[] | [];
	paymentUrl: string | undefined;
	channels: string[] | [];
	filesUploaded: any[] | [];
	paginatedTickets: Pagination<ITicket[], 'data'> | undefined;
	loadingContracts: boolean;
	urlSignedDocument: string | undefined;
	getPaginatedAgencies: (
		request: IGetAgencyRequest,
	) => Promise<Pagination<IAgencySignup[], 'data'> | null>;
	getPaginatedProgress: (
		request: IGetAgencyRequest,
	) => Promise<Pagination<IProgressType[], 'data'> | null>;
	createAgency: (body: IAgencySignup) => Promise<void>;
	getLeadsAgency: (
		request: IGetAgencyRequest,
	) => Promise<Pagination<ICustomerLead[], 'data'> | null>;
	getOutcome: () => Promise<IEsito[]>;
	setCredits: (
		uuid: string,
		credit: number,
		numFattura: string,
		creditSms: string,
	) => Promise<void>;
	setEnabled: (uuid: string, toggle: boolean) => Promise<void>;
	setGroupToAgency: (uuid: string, group: string) => Promise<void>;
	getRevisionHistory: (id: number) => Promise<Pagination<IAgencySignup[], 'data'> | undefined>;
	getAgencyInformation: (id: string) => Promise<IAgencySignup | undefined>;
	requestNewLeads(
		uuid: string,
		requestLead: number,
		distributionDaily: number,
		valueSms: number,
	): Promise<void>;
	changePasswordFirstLogin(
		uuid: string,
		password: string,
		confermaPassword: string,
		isAdmin: boolean,
	): Promise<void>;
	updateEsitoLead: (
		leadId: string,
		body: IMicroEsito,
		note: string,
		currentPage: number,
	) => Promise<ICustomerLead>;
	exportReport: (type: string) => Promise<void>;
	createMicroEsito: (body: IMicroEsito) => Promise<void>;
	deleteMicroEsito: (id: number) => Promise<void>;
	getAllMicroEsiti: () => Promise<IMicroEsito[]>;
	uploadDocument: (file: File, leadUuid: string, praticaId: string) => Promise<void>;
	downloadDocument: (id: string, leadUuid: string, praticaId: string) => Promise<Blob>;
	getAllDocuments: (leadUuid: string, praticaId: string) => Promise<string[]>;
	getContracts: (leadUuid: string) => Promise<string[]>;
	deleteDocument: (id: string, leadUuid: string, praticaId: string) => Promise<void>;
	startPayment: (amount: string, type: string) => Promise<string>;
	createChannel: (canale: string) => Promise<void>;
	getChannels: () => Promise<string[]>;
	getLeadsFilter: (
		page: number,
		size: number,
		channel: string,
		microesito: string,
	) => Promise<Pagination<ICustomerLead[], 'data'> | null>;
	getFilesUploaded: () => Promise<any[]>;
	updateLeadVisibility: (fileId: string, hide: boolean) => Promise<void>;
	getTicketsByAgencyUuid: (
		page: number,
		size: number,
	) => Promise<Pagination<ITicket[], 'data'> | undefined>;
	updateTicketByUuid: (ticket: ITicket) => Promise<ITicket>;
	createTicket: (ticket: ITicket) => Promise<ITicket>;
	sendSignDocument: (leadUuid: string) => Promise<void>;
	getSignedDocument: (leadUuid: string) => Promise<string>;
}

export const useAgencyStore = create<IAgencyState>()((set, get) => ({
	revisionsAgency: undefined,
	paginatedAgencies: undefined,
	agencyInformation: undefined,
	loadingAgency: false,
	paginatedLeadAgency: undefined,
	outcomes: [],
	microEsiti: [],
	paginatedProgress: undefined,
	documents: [],
	contracts: [],
	paymentUrl: undefined,
	channels: [],
	filesUploaded: [],
	paginatedTickets: undefined,
	loadingContracts: false,
	urlSignedDocument: undefined,
	getPaginatedAgencies: async ({ page, value, size }) => {
		set({
			loadingAgency: true,
		});
		const response = await AgencyService.getPaginatedAgencies({
			page,
			value: value || '',
			size: size || DEFAULT_ITEMS_FOR_PAGE,
		});
		set({
			loadingAgency: false,
			paginatedAgencies: response.data,
		});
		return response.data;
	},
	createAgency: async (body) => {
		try {
			const response = await AgencyService.createAgency(body);
			showNotification('Successo', 'Agenzia creata', 'success');
			await get().getPaginatedAgencies({ page: 0, value: '', size: DEFAULT_ITEMS_FOR_PAGE });
			return response.data;
		} catch (error) {
			showNotification('Errore', 'Errore durante la creazione', 'danger');
			throw new Error('Errore durante la creazione agenzia');
		}
	},
	getLeadsAgency: async ({ page, value, size }) => {
		set({
			loadingAgency: true,
		});
		const response = await AgencyService.getLeadsAgency({
			page,
			value: value || '',
			size: size || DEFAULT_ITEMS_FOR_PAGE,
		});
		set({
			paginatedLeadAgency: response.data,
			loadingAgency: false,
		});
		return response.data;
	},
	getOutcome: async () => {
		const response = await AgencyService.getOutcome();
		set({
			outcomes: response.data,
		});
		return response.data;
	},
	setCredits: async (uuid, credit, numFattura, creditSms) => {
		const response = await AdminService.setCredits(uuid, credit, numFattura, +creditSms);
		const { paginatedAgencies } = get();
		set({
			paginatedAgencies: {
				page: paginatedAgencies?.page || ({} as Page),
				data:
					paginatedAgencies?.data.map((agency) => {
						if (agency.uuid === uuid) {
							return {
								...agency,
								credits: (agency.credits || 0) + credit,
								creditiSms: (agency.creditiSms || 0) + +creditSms,
								numFattura: numFattura,
							};
						}
						return agency;
					}) || [],
			},
		});
		showNotification('Successo', 'Credito aggiornato correttamente', 'success');
		return response;
	},
	setEnabled: async (uuid, toggle) => {
		const response = await AdminService.setEnabled(uuid, toggle);
		const { paginatedAgencies } = get();
		set({
			paginatedAgencies: {
				page: paginatedAgencies?.page || ({} as Page),
				data:
					paginatedAgencies?.data.map((agency) => {
						if (agency.uuid === uuid) {
							return {
								...agency,
								enabled: toggle,
							};
						}
						return agency;
					}) || [],
			},
		});
		return response;
	},
	getRevisionHistory: async (id) => {
		const response = await AgencyService.getRevisionsAgencies(id);
		set({ revisionsAgency: response.data });
		return response.data;
	},
	getAgencyInformation: async (id) => {
		const response = await AgencyService.getAgencyInformation(id);
		set({ agencyInformation: response.data });
		return response.data;
	},
	requestNewLeads: async (uuid, requestLead, distributionDaily, valueSms) => {
		const response = await AgencyService.requestNewLeads(
			uuid,
			requestLead,
			distributionDaily,
			valueSms,
		);
		showNotification('Successo', 'Richiesta inviata correttamente', 'success');
		return response;
	},
	changePasswordFirstLogin: async (uuid, password, confermaPassword, isAdmin) => {
		const response = await AgencyService.changePasswordFirstLogin(
			uuid,
			password,
			confermaPassword,
			isAdmin,
		);
		showNotification('Successo', 'Password cambiata correttamente, accedi nuovamente', 'success');
		return response;
	},
	updateEsitoLead: async (leadId, body, note, currentPage) => {
		console.log('Body', body, note, leadId);
		const response = await AgencyService.updateEsitoLead(leadId, body, note);
		const updatedLead = response.data;
		const { paginatedLeadAgency } = get();
		if (paginatedLeadAgency) {
			const updatedLeads = paginatedLeadAgency.data.map((lead) =>
				lead.uuid === leadId ? updatedLead : lead,
			);
			set({
				paginatedLeadAgency: {
					...paginatedLeadAgency,
					data: updatedLeads,
				},
			});
		}
		showNotification('Successo', 'Lead aggiornato correttamente', 'success');
		return response.data;
	},
	setGroupToAgency: async (uuid, group) => {
		const response = await AdminService.assignGroupToAgency(uuid, group);
		get().getPaginatedAgencies({ page: 0, value: '', size: DEFAULT_ITEMS_FOR_PAGE });
		showNotification('Successo', 'Gruppo assegnato correttamente', 'success');
		return response;
	},
	exportReport: async (type) => {
		try {
			const response = await AdminService.exportReport(type);
			showNotification('Successo', 'Richiesta di report andata a buon fine!', 'success');
			const contentType = response.headers['content-type'];
			const data = response.data;

			const binaryString = atob(data);

			const arrayBuffer = new ArrayBuffer(binaryString.length);
			const uint8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < binaryString.length; i++) {
				uint8Array[i] = binaryString.charCodeAt(i);
			}

			const blob = new Blob([arrayBuffer], { type: contentType });

			const blobUrl = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = `report_operatori_${moment().format('DD-MM-yyyy')}.xlsx`;
			link.click();

			URL.revokeObjectURL(blobUrl);
		} catch (error) {
			console.error('Errore durante il download del report:', error);
			showNotification(
				'Errore',
				'Si è verificato un errore durante il download del report',
				'error',
			);
			throw error;
		}
	},
	getPaginatedProgress: async ({ page, value, size }) => {
		set({
			loadingAgency: true,
		});
		const response = await AgencyService.getPaginatedProgress({
			page,
			value: value || '',
			size: size || DEFAULT_ITEMS_FOR_PAGE,
		});
		set({
			loadingAgency: false,
			paginatedProgress: response.data,
		});
		return response.data;
	},
	createMicroEsito: async (body) => {
		const response = await AgencyService.createMicroEsito(body);
		showNotification('Successo', 'Micro esito creato correttamente', 'success');
		return response.data;
	},
	deleteMicroEsito: async (id) => {
		const response = await AgencyService.deleteMicroEsito(id);
		showNotification('Successo', 'Micro esito eliminato correttamente', 'success');
		return response.data;
	},
	getAllMicroEsiti: async () => {
		const response = await AgencyService.getAllMicroEsiti();
		set({ microEsiti: response.data });
		return response.data;
	},
	uploadDocument: async (file, leadUuid, praticaId) => {
		const response = await AgencyService.uploadDocument(file, leadUuid, praticaId);
		showNotification('Successo', 'Documento caricato correttamente', 'success');
		set({ documents: [...get().documents, file.name] });
		return response.data;
	},
	downloadDocument: async (id, leadUuid, praticaId) => {
		const response = await AgencyService.downloadDocument(id, leadUuid, praticaId);
		const contentType = response.headers['content-type'];
		const data = response.data;

		const blob = new Blob([data], { type: contentType });

		const blobUrl = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = blobUrl;
		link.download = `${id}`;
		link.click();

		URL.revokeObjectURL(blobUrl);
		return response.data;
	},
	getAllDocuments: async (leadUuid, praticaId) => {
		const response = await AgencyService.getAllDocuments(leadUuid, praticaId);
		set({ documents: response.data });
		return response.data;
	},
	deleteDocument: async (id, leadUuid, praticaId) => {
		await AgencyService.deleteDocument(id, leadUuid, praticaId);
		showNotification('Successo', 'Documento eliminato correttamente', 'success');
	},
	startPayment: async (amount, type) => {
		const response = await AgencyService.startPayment(amount, type);
		window.open(response.data, '_blank');
		return response.data;
	},
	createChannel: async (canale) => {
		await AgencyService.createChannel(canale);
		set({ channels: [...get().channels, canale] });
		showNotification('Successo', 'Canale creato correttamente', 'success');
	},
	getChannels: async () => {
		const response = await AgencyService.getChannels();
		set({ channels: response.data });
		return response.data;
	},
	getLeadsFilter: async (page, size, channel, microesito) => {
		set({
			loadingAgency: true,
		});
		const response = await AgencyService.getLeadsFilter(page, size, channel, microesito);
		set({
			paginatedLeadAgency: response.data,
			loadingAgency: false,
		});
		return response.data;
	},
	getFilesUploaded: async () => {
		const response = await AgencyService.getFilesUploaded();
		set({ filesUploaded: response.data });
		return response.data;
	},
	updateLeadVisibility: async (fileId, hide) => {
		set({ loadingAgency: true });
		await AgencyService.updateVisibilitLeadByFile(fileId, hide);
		set({ loadingAgency: false });
		showNotification('Successo', 'Visibilità aggiornata correttamente', 'success');
	},
	getTicketsByAgencyUuid: async (page, size) => {
		const response = await AgencyService.getTicketsByAgencyUuid(page, size);
		set({ paginatedTickets: response.data });
		return response.data;
	},
	updateTicketByUuid: async (ticket) => {
		const response = await AgencyService.updateTicketByUuid(ticket);
		get().getTicketsByAgencyUuid(0, 100);
		return response.data;
	},
	createTicket: async (ticket) => {
		const response = await AgencyService.createTicket(ticket);
		get().getTicketsByAgencyUuid(0, 100);
		return response.data;
	},
	getContracts: async (leadUuid) => {
		set({ loadingContracts: true });
		const response = await AgencyService.getContracts(leadUuid);
		set({ contracts: response.data, loadingContracts: false });
		return response.data;
	},
	sendSignDocument: async (leadUuid) => {
		await AgencyService.sendSignDocument(leadUuid);
		showNotification('Successo', 'Documento inviato correttamente', 'success');
	},
	getSignedDocument: async (leadUuid) => {
		const response = await AgencyService.getSignedDocument(leadUuid);
		set({ urlSignedDocument: response.data });
		return response.data;
	},
}));
